// extracted by mini-css-extract-plugin
export var home_backg_link = "Container-module--home_backg_link--wZQRK";
export var home_backg_logo = "Container-module--home_backg_logo--p84fx";
export var home_backg_rectangle3 = "Container-module--home_backg_rectangle3--Z-370";
export var home_header = "Container-module--home_header--w0d77";
export var home_img = "Container-module--home_img--mZ1TC";
export var home_img_link = "Container-module--home_img_link--26wfa";
export var home_main = "Container-module--home_main--mKDMm";
export var home_main1 = "Container-module--home_main1--Mry85";
export var home_rectangle3_container = "Container-module--home_rectangle3_container--Px6GT";
export var home_rectangle3_text = "Container-module--home_rectangle3_text--fmDWK";
export var home_title_h1 = "Container-module--home_title_h1--A5C+y";
export var linkMenu = "Container-module--linkMenu--bwnyx";