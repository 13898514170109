import React from "react"
import app from '../firebase/firebase';
import { getAnalytics, logEvent } from "firebase/analytics";
import Seo from '../components/Seo/Seo';
import Container from '../components/Container/Container';
import * as classes from './download.module.css';
import { useWidth } from '../hook/useWidth';
import Scroll from '../components/Scroll/Scroll';
import { StaticImage } from 'gatsby-plugin-image';

const Download = () => {

    const { width } = useWidth();

    React.useEffect(() => {
      if (!app) {
        return
      }
      const analytics = getAnalytics(app);
      logEvent(analytics, 'visited_download');
    }, [])

  return (
    <div className={classes.downloadBackground}>
    <Scroll />
    <Container>
        <Seo title={`Download`}/>
        <div className={classes.downloadContainer}>
            <span className={classes.titleDownload}>Download Now!</span>
            <section className={classes.iPhone}>
              <div className={classes.qr_code}>
                <StaticImage src='../images/desktop/qr_Code.svg' alt="Qr KChat App" className={classes.imgQR}/>
              </div>
              <div className={classes.welcomeKchat}>
                <h3 className={classes.welcomeKchat_h3}>Welcome to the Kchat App</h3>
              </div>
              <div className={classes.clickDownload}>
                <h3 className={classes.clickDownload_h3}>Click here to download</h3>
              </div>
              <div className={classes.app_images}>
                <a href={ 
                             width < 950 
                                       ? 
                                        "https://apps.apple.com/us/app/kchat-the-key-to-chat-safely/id1510149456"
                                      :
                                        "https://apps.apple.com/us/app/kchat-the-key-to-chat-safely/id1510149456"
                            } 
                      alt="App Store">
                <StaticImage src='../images/mobile/App_Store.svg' alt="App Store" className={classes.home_download_img1} />
                </a>
                <a  className={classes.home_download_a}   href={
                      width < 950 
                                    ?
                                    "https://play.google.com/store/apps/details?id=com.knesis.kchat"
                                    : 
                                    "https://play.google.com/store/apps/details?id=com.knesis.kchat"
                        } 
                      alt="Google Play">
                <StaticImage src='../images/mobile/Google_Play.png' alt="Google Play" className={classes.home_download_img}/>
                </a>
            </div> 
            </section>  
        </div>
    </Container>
    <footer className={classes.fotterDownload}> 
    </footer> 
    </div>
  )
}

export default Download
