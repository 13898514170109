// extracted by mini-css-extract-plugin
export var app_images = "download-module--app_images--KQ+BO";
export var clickDownload = "download-module--clickDownload--V90Lc";
export var clickDownload_h3 = "download-module--clickDownload_h3--HKsgU";
export var downloadBackground = "download-module--downloadBackground--1ltAB";
export var downloadContainer = "download-module--downloadContainer--eV48j";
export var fotterDownload = "download-module--fotterDownload--6wECf";
export var home_download_a = "download-module--home_download_a--X92yE";
export var home_download_img = "download-module--home_download_img--eplSx";
export var home_download_img1 = "download-module--home_download_img1--sn5tF";
export var iPhone = "download-module--iPhone--wuXXJ";
export var imgQR = "download-module--imgQR--iH28h";
export var qr_code = "download-module--qr_code--f-SW+";
export var titleDownload = "download-module--titleDownload--O7J4w";
export var welcomeKchat = "download-module--welcomeKchat--FM2Kn";
export var welcomeKchat_h3 = "download-module--welcomeKchat_h3--PfSei";