import React from "react";
import * as classes from './Container.module.css';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import Nav from "../Nav/Nav";

const Container = ({ children }) => (
    <>
        <header className={classes.home_header}>
                <section className={classes.home_backg_logo }>
                  <Link to={`/`} className={classes.linkMenu}>
                    <ArrowBackRoundedIcon sx={{color: "white"}}/>
                  </Link>
                  <Nav />
                  <Link to={`/`} className={classes.home_img_link}>
                    <StaticImage src='../../images/desktop/App_Logo@3x@2x.png' alt="App_Logo" className={classes.home_img} />
                  </Link>
                  <ArrowBackRoundedIcon sx={{visibility: "hidden"}} className={classes.linkMenu}/>
                </section>
        </header>
        <main className={classes.home_main}>
          {children}
        </main>
    </>
);
export default Container;